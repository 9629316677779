import { HubConnectionBuilder } from '@microsoft/signalr';
import { baseURLV1 } from '../api/axiosInstance';
import { getItem } from '../helpers/cookie.helper';
import TokensEnum from '../enums/tokens.enum';

class SignalRSingleton {
  constructor() {
    this.userHubConnected = false;
    this.slotHubConnected = false;
    this.tournamentHubConnected = false;
  }

  async connectToUserHub() {
    this.userHubConnection = new HubConnectionBuilder()
      .withUrl(
        `${baseURLV1}/UserHub?access-token=${getItem(TokensEnum.SLOT_TOKEN)}`
      )
      .withAutomaticReconnect()
      .build();

    await this.userHubConnection.start();

    this.userHubConnected = true;
  }

  disconnectFromUserHub() {
    if (this.userHubConnected && this.userHubConnection) {
      this.userHubConnection.stop().then(() => {
        this.userHubConnected = false;
      });
    }
  }

  async connectToSlotHub() {
    this.slotHubConnection = new HubConnectionBuilder()
      .withUrl(`${baseURLV1}/SlotHub`)
      .withAutomaticReconnect()
      .build();

    await this.slotHubConnection.start();

    this.slotHubConnected = true;
  }

  async connectToTournamentHub(tournamentId) {
    const token = getItem(TokensEnum.SLOT_TOKEN);

    if (!token || this.tournamentHubConnected) {
      return;
    }

    this.tournamentHubConnection = new HubConnectionBuilder()
      .withUrl(
        `${baseURLV1}/TournamentHub?access-token=${token}&tid=${tournamentId}`
      )
      .withAutomaticReconnect()
      .build();

    await this.tournamentHubConnection.start();

    this.tournamentHubConnected = true;
  }

  disconnectFromTournamentHub() {
    if (this.tournamentHubConnected && this.tournamentHubConnection) {
      this.tournamentHubConnection.stop().then(() => {
        this.tournamentHubConnected = false;
      });
    }
  }
}

export default new SignalRSingleton();
